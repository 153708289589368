#project{
    color: rgba(255, 255, 255, 0);
    position: relative;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    margin: 15px;
     /*might remove later*/
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.grad{
    display: inline-block;
    position: relative;
  height: 250px;
  width: 250px;
    background:linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%); 
}

#project-img
{
    max-width: 250px;
    max-height: 250px;
    border-radius: 8px;
    border:solid;
    border-color: rgba(240, 248, 255, 0);
}

#project-img:hover
{
    border: solid;
    border-color: rgb(255, 0, 140);
    animation: flicker 5s 4s infinite;
}

@keyframes flicker {
    0%,100% {
      border: solid rgba(255, 0, 140, 1);
    }
    20% {
        border: solid rgba(255, 0, 140, .4);
    }
    25% {
        border: solid rgba(255, 0, 140, 1);
    }
    30% {
        border: solid rgba(255, 0, 140, .4);
    }
    35% {
        border: solid rgba(255, 0, 140, 1);
    }
  }


#project:hover
{
    cursor: pointer;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

#project-details
{

    position:absolute;
    padding-left: 0%;
    bottom: 8px;
    left: 15px;
}

#project-summary
{
    display:none;
}

#project-viewButton
{
    height: 50px;
    width: 50%;
    border-radius: 10px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: bold;
    justify-content: space-between;
    position: relative;
    left: 25%;
    display: none;
}

#project-viewButton :hover
{
    color: black;
}

@media (max-width: 768px) {
    
    #project
    {
      width: 90%;
      height: 90%;
      flex-direction: column;
      padding-right: 0%;
      
    }

    #project-img
    {
        width: 100%;
        height: 100%;
    }

    #project-summary
    {
    display:block;
    }

    #project-viewButton
    {

        text-decoration: none;
        display:block;
    }

    #project-viewButton :hover
    {
    color: black;
    }

}
#title{
    color: white;
    margin-right: 80%;
    border-image: 
    linear-gradient(0deg,
      #ffffff 2px, /* color and thickness */
    #0000 0) fill 0//0 80vw 0 0;
}

#portfolioDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}



#portfolioSpan{
    background-color: rgb(40, 40, 40);
    display: flex;
    flex-direction: row;
}
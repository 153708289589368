.project-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  
  .display-image {
    display: flex;
    position: relative;
    margin-bottom: 20px;
}
  
  .display-image img {
    width: 40%;
    height: 40%;
    border-radius: 8px;
    box-shadow: 0 0 10px #8c0ff7, 0 0 20px #8c0ff7, 0 0 40px #8c0ff7, 0 0 80px #8c0ff7, 0 0 120px #8c0ff7, 0 0 150px #8c0ff7;
    animation: neon-pulse 1.5s ease-in-out infinite alternate;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    margin: auto;
}

  .neon-border {
    border-radius: 8px;
    box-shadow: 0 0 10px #8c0ff7, 0 0 20px #8c0ff7, 0 0 40px #8c0ff7, 0 0 80px #8c0ff7, 0 0 120px #8c0ff7, 0 0 150px #8c0ff7;
    animation: neon-pulse 1.5s ease-in-out infinite alternate;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

@keyframes neon-pulse {
    0% {
        /* Initial glow effect */
        box-shadow: 0 0 10px #8c0ff7, 0 0 20px #8c0ff7, 0 0 30px #bf267a, 0 0 40px #bf267a, 0 0 50px #bf267a, 0 0 60px #bf267a;
    }
    100% {
        /* Final glow effect */
        box-shadow: 0 0 15px #8c0ff7, 0 0 30px #8c0ff7, 0 0 40px #bf267a, 0 0 50px #bf267a, 0 0 60px #bf267a, 0 0 70px #bf267a;
    }
}

  .project-details {
    position: absolute;
    top: 30%;
    left: 0%;
    padding: 10px;
    border-radius: 8px;
    color: white;
    opacity: 100%;
    font-weight: bold;
}

.project-details p
{
  width: 40%;
}

  .project-details button
  {
    height: 60px;
    width: 120px;
    border-radius: 4px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: bold;
}

.project-details button:hover
  {
    
    opacity: 50%;
    cursor: pointer
  }
  
  .display-image:hover .project-details {
    display: block;
  }

.thumbnail-container
{
  display: grid;
     /* Adjust minmax values as needed */
    grid-template-columns: repeat(5,1fr);
 /* Adjust as needed for spacing between items */
    justify-content:space-between;
    
}
  
  #projectFilter{
    color: white;
  }

  .setTextWhite
  {
    color: white;
  }

  #showProjectsButton
  {
    height: 60px;
    width: 120px;
    border-radius: 4px;
    border: none;
    background-color:  #00a4d6;
    color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    

    .thumbnail-container
    {
    background-color: rgb(40, 40, 40);
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    margin-left: 20px;
    text-align: center;
    
    } 

.display-image
{
  display: flex;
  left: 25%;
  display:none;
}

   .display-image img
   {
    display: flex;
    height: 60%;
    width: 60%;
   }

   .project-details
   {
    height: 40%;
    width: 50%;
    font-size: 40%;
    top: 50%;
    display: none;

   }

   .project-details button
   {
    height: 30%;
    width: 40%;
    font-size: 8px;
   }
}
  
#aboutSection
{
    scroll-margin-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: top;
    font-size: calc(10px + 2vmin);
    color: white;
    max-width: 100%;
    width: 100%;
    padding-bottom: 20px;
    background-color: black;
}

#imageSection
{
    display: none;
   max-height: 50%;
  
}

#hiddenSection
{
    display: none;
}

#textSection
{
    display: flex;
    background-color:rgb(0, 0, 0);
    text-align: left;
    max-width: 100%;
    max-height: 50%;
    font-weight:bold;
    align-items: center;
    text-align: center;
    
}

#text
{
    color:rgb(255, 255, 255);
    width: 50%;
    margin-left: 25%;
}

#aboutText
{
    
}




#image
{
   
    max-width: 480px;
    max-height: 480Spx; 
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-left: 5%;
}

@media (max-width: 768px) {
    #textSection
    {
        font-size: auto;
        max-width:100%;
        text-align: center;


    }

    #textSection h1
    {
        margin-left:-25%;
    }


    #aboutSection
    {
        flex-direction: column;
    }

}
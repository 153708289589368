#home{
  scroll-margin-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: calc(10px + 2vmin);
    color: white;
    max-width: 100%;
    width: 100%;
    padding-bottom: 20px;
    background-image: url('Images/jada_banner.png');
    background-size: 100% 100%;
    
  }

#home h4
{
  text-decoration: underline;
}

#aboutTitle {
  margin-top: 10%;
}

#aboutImage{
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 250px;
}

#project-button
{
    height: 50px;
    width: 10%;
    border: solid;
    border-color: black;
    background-color:  #00a4d600;
    color: white;
    font-weight: bold;

    justify-content: space-between;
    position: relative;
    left: 0%;
}

#project-button:hover
{
    color: rgb(255, 255, 255);
    cursor:pointer;
    background-color:#000000;
    transition: background-color .5s ease;
    -webkit-transition: background-color .5s ease;
    -moz-transition: background-color .5s ease;
    -ms-transition: background-color .5s ease;
    -o-transition: background-color .5s ease;
}

.highlight
{
  text-shadow: 50%;
  color: #00a4d6;
}

#intro
{
  text-align: center;
}

@media (max-width: 768px) {
    

  #home
  {
  background-color: rgb(40, 40, 40);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 80px;
  text-align: center;
  background-size: 100% 100%;
  
  } 

  #project-button
  {
    width: 20%;
  }
}
